<template>
  <div class="extensionManage_page">
    <div class="top_holder"></div>
    <!-- 面包屑 -->
    <div class="breadcrumb web-center" style="margin: 2.5rem auto 1.25rem">
      <a-breadcrumb>
        <a-breadcrumb-item
          ><a href="#/extension">插件中心</a></a-breadcrumb-item
        >
        <a-breadcrumb-item
          ><a href="javascript:;">插件管理</a></a-breadcrumb-item
        >
      </a-breadcrumb>
    </div>
    <!-- 列表展示 -->
    <div class="list web-center">
      <div v-for="item in listData" :key="item.id" class="list_item">
        <div class="item_top">
          <img
            :src="item.icon_url"
            style="width: 3rem; height: 3rem; border-radius: 0.5rem"
            alt=""
          />
          <div class="item_info">
            <div class="item_title">{{ item.title }}</div>
            <div class="item_desc">{{ item.subtitle }}</div>
          </div>
        </div>
        <div class="item_footer">
          <span
            style="color: #4c84ff; cursor: pointer"
            @click="$router.push(`/extension/detail?id=${item.id}`)"
            >详细信息</span
          ><a-button
            type="primary"
            style="margin-left: 20px"
            @click="handleDispatch(item)"
            size="small"
          >
            分配环境 </a-button
          ><a-button
            type="danger"
            style="margin-left: 20px"
            @click="handleUninstall(item)"
            size="small"
          >
            卸载插件
          </a-button>
        </div>
      </div>
      <!-- 空白页 -->
      <div class="noResult" v-show="!listData.length">
        <img
          src="@/assets/img/extension/exManage_noResult.png"
          alt=""
          class="img"
        />
        <p class="noResult_title">您还未安装任何插件</p>
        <div class="noResult_btn" @click="$router.push('/extension')">
          获取更多插件
        </div>
      </div>
    </div>
    <!-- 测试表格 -->
    <!-- 添加插件弹窗 -->
    <AddExtension
      :visible="visible"
      :extensionItem="item"
      @close="visible = false"
    ></AddExtension>
  </div>
</template>

<script>
import { cancelInstall, installPlugin } from "@/api/extension.js";
import AddExtension from "../components/AddExtension.vue";
export default {
  components: {
    AddExtension,
  },
  data() {
    return {
      extensionId: "",
      guid: "",
      visible: false,
      listData: [],
      download_url: "",
      item: {},
      test: "hello",
    };
  },
  computed: {
    testvalue() {
      return this.test;
    },
  },
  created() {
    this.getInstallPlugin();
  },
  // computed: {
  //   unInstall() {
  //     return this.$store.state.unInstall;
  //   },
  // },
  // watch: {
  //   async unInstall(newVal) {
  //     if (newVal) {
  //       if (newVal) {
  //         cancelInstall({ id: item.id }).then((res) => {
  //           if (res.data.code == 200) {
  //             this.$message.success("已成功卸载");
  //             this.getInstallPlugin();
  //           }
  //         });
  //       }
  //     }
  //   },
  // },
  methods: {
    sendToWebsocket() {
      let c_1 = JSON.stringify({
        message: "/connection/deletePlug",
        id: this.extensionId,
        guid: this.guid,
        download_url: this.download_url,
      });
      // let c_1 = `{"message": "/connection/success"}`;
      // 加密
      // const XORencryption = (val) => {
      //   if (typeof val !== "string") return val;
      //   val = this.$Base64.encode(val);
      //   var m = "y";
      //   let message = "";
      //   for (var i = 0; i < val.length; i++) {
      //     if (0 == i % 2) {
      //       message += String.fromCharCode(val.charCodeAt(i) ^ m.charCodeAt(0));
      //     } else {
      //       message += val[i];
      //     }
      //   }
      //   return this.$Base64.encode(message);
      // };
      let b_1 = this.$encrypt(c_1);
      this.$store.state.websocket.send(b_1);
    },
    getInstallPlugin() {
      installPlugin().then(({ data: { data } }) => {
        this.listData = data;
      });
    },
    handleDispatch(item) {
      if (!item.auth) {
        this.$message.error("你没有当前插件的操作权限，请联系公司管理员");
        return;
      }
      item.is_install = true;
      this.item = item;
      this.visible = true;
    },
    handleUninstall(item) {
      this.$confirm({
        title: "确定卸载该插件吗吗?",
        onOk: () => {
          this.extensionId = item.id;
          this.guid = item.guid;
          this.download_url = item.download_url;
          this.sendToWebsocket();
          cancelInstall({ id: item.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message.success("已成功卸载");
              this.getInstallPlugin();
            }
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.extensionManage_page {
  .top_holder {
    height: 3.75rem;
    background-color: #fff;
  }
  .list {
    .flex(flex-start;@wrap:wrap);
    .list_item {
      width: 386px;
      padding: 20px;
      margin-right: 20px;
      margin-bottom: 10px;
      border-radius: 4px;
      background-color: #fff;
      &:nth-child(3n) {
        margin-right: 0px;
      }
      .item_top {
        .flex(@align:flex-start);
        .item_info {
          margin-left: 10px;
          width: 290px;
        }
        .item_title {
          margin-bottom: 15px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #374567;
          .ellipsis();
        }
        .item_desc {
          height: 70px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7882a1;
          line-height: 22px;
          .ellipsis-multi(3);
        }
      }
      .item_footer {
        margin-top: 15px;
      }
    }
    .noResult {
      width: 100%;
      height: 642px;
      .flex(center;center;@direction: column);
      .img {
        width: 280px;
        height: 142px;
      }
      .noResult_title {
        margin: 12px auto;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #374567;
        line-height: 25px;
      }
      .noResult_btn {
        width: 160px;
        height: 38px;
        border: 1px solid #295eff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #295eff;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>